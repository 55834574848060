<template>
  <div class="e-page-homepage relative flex-grow w-full bg-white pb-footer-safe lg:pt-0 lg:pb-0" :class="{'animate-pulse' : loading && !firstLoad}">
    <HeaderLight class="lg:hidden" :top-nav="true" />

    <Loadmore class="relative" :on-refresh="refresh">
      <section class="pl-4 sm:content mb-4 sm:hidden">
        <Swiper :slides-offset-before="10" :slides-offset-after="10" :init-classes="'flex gap-4 lg:gap-12'" :slides-per-view="'auto'" :space-between="10">
          <template v-slot="{readyHandler, initialized}">
            <SwiperSlide class="w-28" v-for="(item, index) in universesHome" @ready="readyHandler" :key="index">
              <SlideUniverseHome :index="index" :data="item" />
            </SwiperSlide>
          </template>
        </Swiper>
      </section>

      <BannerImage v-if="!$screen.mobile"
                   :image="banner[activeBanner].src"
                   classes="h-[31rem] bg-contain bg-center"
                   :fill-color="banner[activeBanner].fillColor"
                   :dark="banner[activeBanner].dark"
                   text-centered
                   store-buttons
                   bg-position="center center"
                   :headline="$t('home.banner.headline')"
                   :catchphrase="$t('home.banner.catchphrase')" />


      <!-- SLIDER HOW -->
      <section class="pt-6 md:pt-12 pl-4 sm:content bg-white relative" v-if="sliderHow">
        <Swiper :init-classes="'flex'"
                :slides-per-view="'auto'"
                :space-between="0"
                :fade="sliderHow.length > 3 && !$screen.mobile">
          <template v-slot="{readyHandler, initialized}">
            <SwiperSlide class="px-2 w-auto" v-for="(item, index) in sliderHow" @ready="readyHandler" :key="index">
              <SlideHow :index="index" :data="item" />
            </SwiperSlide>
          </template>
        </Swiper>
      </section>

      <!-- SHOP AMBASSADORS -->
      <ProductSwiper :title="$t('home.title.shop.ambassadors')"
                     :gtm-item-list-name="gtmItemListName"
                     :description="$t('home.description.shop.ambassadors')"
                     :products="athleteProducts"/>

      <section class="my-8 pl-4 sm:content">
        <Swiper
          :slides-per-view="'auto'">
          <template v-slot="{readyHandler, initialized}">
            <SwiperSlide v-for="(item, index) in articles"
                         class="px-2 w-3/4 lg:w-1/2"
                         :class="{
                            'md:pr-4': index === 0,
                            'md:pl-4': index === 1,
                         }"
                         @ready="readyHandler" :key="index">
              <SlideArticle :index="index" :picture="item.picture" :title="$t(item.title)" :cta="$t(item.cta)"
                                  :link="localePath({name : item.link})"/>
            </SwiperSlide>
          </template>
        </Swiper>
      </section>

      <BrandSwiper v-if="partners"
                   :brands="partners.items"
                   :description="partners.description"
                   :title="partners.label"/>


      <ProductSwiper v-for="catalog in promoteProducts"
                     :key="catalog.url_all"
                     :all-url="catalog.url_all"
                     :title="catalog.label"
                     :image="catalog.image"
                     :description="catalog.description"
                     :gtm-item-list-name="gtmItemListName"
                     :products="catalog.products"/>

      <div v-if="!$screen.xs" :class="{'pb-footer':$screen.md }">
        <section class="content my-20 flex gap-4 lg:gap-14 justify-around">
          <Universe v-for="(universe, index) in universes" :data="universe" :key="index"/>
        </section>
        <Newsletter />
        <Advices />
        <Reassurance />
      </div>
      <div class="flex justify-center" v-else>
        <nuxt-link :to="localePath({name: 'search'})">
          <Button color="primary">
            {{ $t('load.more.product')}}
          </Button>
        </nuxt-link>
      </div>
    </Loadmore>
  </div>
</template>

<script>
import {refresh} from "@/mixins/screen";
import transition from "@/mixins/transition";
import {DEFAULT_IMG} from "assets/js/constants/constant";
import {ITEM_LISTS} from 'assets/js/gtm-events';
import {mapGetters, mapMutations} from "vuex";
import HeaderLight from /* webpackChunkName: "HeaderLight" */"@/components/header/HeaderLight.vue";
import {toLightProduct} from "assets/js/utils/product";
import Loadmore from "@/components/Loadmore.vue";
import Swiper from "@/components/Swiper.vue";
import SwiperSlide from "@/components/SwiperSlide.vue";
import SlideUniverseHome from "@/components/slider/SlideUniverseHome.vue";
import SlideHow from "@/components/slider/SlideHow.vue";
import SlideArticle from "@/components/slider/SlideArticle.vue";
import Universe from "@/components/Universe.vue";
import Newsletter from "@/components/Newsletter.vue";
import Reassurance from "@/components/Reassurance.vue";
import Advices from "@/components/Advices.vue";
import StoreButtons from /* webpackChunkName: "StoreButtons" */"@/components/buttons/StoreButtons.vue";
import BannerImage from /* webpackChunkName: "BannerImage" */"@/components/landing/BannerImage.vue";
import BrandSwiper from /* webpackChunkName: "BrandSwiper" */"@/components/brands/BrandSwiper.vue";
import Button from /* webpackChunkName: "Button" */"@/components/buttons/Button.vue";
import ProductSwiper from /* webpackChunkName: "ProductSwiper" */"@/components/product/ProductSwiper.vue";

export default {
  name: 'homepage',
  scrollToTop: !process.env.mobileApp,
  mixins: [refresh, transition],
  auth: false,
  components: {
    Advices,
    Reassurance,
    Newsletter,
    Universe,
    SlideArticle,
    SlideHow,
    SlideUniverseHome,
    SwiperSlide,
    Swiper,
    Loadmore,
    ProductSwiper,
    Button,
    BrandSwiper,
    BannerImage,
    StoreButtons,
    HeaderLight,
  },
  gtm: {
    pageView: {
      category: 'home',
      title: 'home',
    }
  },
  meta: {
    scrollToTop: !process.env.mobileApp,
    transition: ''
  },
  data() {
    return {
      firstLoad: true,
      loading : false,
      activeBanner: 0,
      banner: [
        {
          src: 'home-cover-1.png',
          fillColor: '#F6FAFC',
          dark: true
        },
        {
          src: 'home-cover-3.png',
          fillColor: '#F6FAFC',
          dark: true
        },
        {
          src: 'home-cover-4.png',
          fillColor: '#F6FAFC',
          dark: true,
        }
      ],
      hero:
        {
          picture: process.env.staticAssets + '/site/hero/hero.png',
          curve: process.env.staticAssets + '/site/curve.png',
          link: this.localePath({name: 'product-add'})
        },
      articles: [
        {
          picture: process.env.staticAssets + '/site/hero/hero-5.png',
          title: 'articles.1.title',
          cta: 'articles.1.cta',
          link: 'page-ambassadors'
        },
        {
          picture: process.env.staticAssets + '/site/hero/hero-4.png',
          title: 'articles.2.title',
          cta: 'articles.2.cta',
          link: 'page-how-to'
        }
      ],
      promoteProducts: [],
      partners: null,
      athleteProducts: [{images: [DEFAULT_IMG]}, {images: [DEFAULT_IMG]}, {images: [DEFAULT_IMG]}, {images: [DEFAULT_IMG]}, {images: [DEFAULT_IMG]}],
    }
  },
  head() {
    return {
      script: [{type: 'application/ld+json', json: this.structuredData}],
      link: [
        {
          rel: 'canonical',
          href: `${process.env.baseUrl}/fr/`
        }
      ],
    }
  },
  async fetch() {
    this.loading = true;

    // Bloc athleteProducts
    const nbAthletesPerPage = 20
    const athleteProducts = await this.$api.$get(`${process.env.apiUrl}/api/v1/products/team?perPage=${nbAthletesPerPage}&with[]=size&with[]=brand&with[]=user`);
    if (athleteProducts && !athleteProducts.error) {
      this.athleteProducts = athleteProducts.map(toLightProduct);
    }

    if (this.config?.app?.home?.products) {
      this.promoteProducts = await Promise.all(this.config?.app?.home?.products?.map(async (item) => {
        let itemProducts = await this.$api.$get(`${process.env.apiUrl}${item.url_api}`);
        if (itemProducts && !itemProducts.error) {
          itemProducts = itemProducts.data?.splice(0, 10).map(toLightProduct);
        } else {
          itemProducts = []
        }
        return {
          ...item,
          products: itemProducts
        }
      }))
    }

    const partners = this.config?.app?.home?.partners

    if (partners) {
      let partnersList = await this.$api.$get(`${process.env.apiUrl}${partners.url_api}`);
      if (partnersList && !partnersList.error) {
        this.partners =  {
          ...partners,
          items: partnersList
        }
      }
    }

    this.firstLoad = false
    this.loading = false;
  },
  created() {
    this.activeBanner = Math.floor(Math.random() * this.banner.length)
    this.$root.$on('refresh', this.$fetch);
  },
  mounted() {
    // Click search in the NavBar = Reset search
    this.$root.$on('navbar:click', () => {
      this.setSearchStatus(false)
    })
  },
  beforeDestroy() {
    this.$root.$off('navbar:click')
  },
  computed: {
    ...mapGetters({
      nav: 'nav/getNav',
      config: 'config/getConfig',
      searchStatus: 'search/getSearchStatus',
    }),
    sliderHow() {
      return this.config?.app?.sliderHow
    },
    universesHome() {
      return this.nav.filter(item => item.isUniverse === 2).map(item => ({
        label : item.name,
        labelShort : item.name.split(' | ')[0],
        image : item.iconImage,
        link :  item.url
      }));
    },
    universes() {
      return this.nav.filter(item => item.isUniverse === 1 && item.id !== 220).map(item => ({
        label : item.name,
        labelShort : item.name.split(' | ')[0],
        image : item.image,
        link :  item.url
      }));
    },
    isApp() {
      return process.env.mobileApp;
    },
    gtmItemListName() {
      return ITEM_LISTS.home;
    },
    structuredData() {
      return {
        "@context": "https://schema.org",
        "@type": "Organization",
        "url": `${process.env.baseUrl}/fr/`,
        "logo": `${process.env.baseUrl}/logo.svg`
      }
    }
  },
  destroyed() {
    this.$root.$off('refresh',this.$fetch);
  },

  methods: {
    ...mapMutations({
      setSearchStatus: 'search/setSearchStatus',
    }),
    refresh(loaded) {
      this.$root.$emit('refresh')
      loaded('done')
    },
  }
}
</script>
